<template>
    <!-- <div class="sidebar"> -->
        <div class="filter">
            <div class="wrap2">
                <div class="item">
                    <label>Fecha</label>
                    <input-date-picker v-model="date" :currentDate="date"></input-date-picker>   
                </div>
                
                <select-service-type 
                    label="NAME" 
                    trackBy="NAME" 
                    @serviceTypeVal="handleServiceTypeValue"
                    selectedHolder="servicios seleccionados">
                </select-service-type>

                <select-service-class 
                    label="DESCR" 
                    trackBy="DESCR" 
                    @serviceClassVal="handleServiceClassValue"
                    selectedHolder="categorías seleccionadas">
                </select-service-class>

                <div class="item">
                    <vs-select label="Cantidad" v-model="limit">
                        <vs-select-item :key="item" :value="item" :text="item" v-for="(item) in limits" />
                    </vs-select> 
                </div>

                <div class="item">
                    <search-supplier flagType="Supplier" @supplier="findSupplier" v-click-outside="closeSuggest" :openSuggestions="openSuggestions"></search-supplier>
                </div>
                
                <div class="tags">
                    <div class="wrapper-tags">
                        <template v-for="(item, idx) in suppliers">
                            <vs-chip :key="idx" transparent color="primary" closable @click="removeSupplier(item, 1)">{{ item }}</vs-chip>
                        </template>
                    </div>
                </div>

            </div>

            <div class="item">
                <vs-button color="primary" type="filled" @click="filter()" :disabled="date == ''">Filtrar</vs-button>
            </div>

        </div>
    <!-- </div> -->
</template>
<script>
import searchSupplier from '@/components/findSupplier.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
import selectServiceType from '@/components/Inputs/input-service-type'
import selectServiceClass from '@/components/Inputs/input-service-class'
export default {
    name: "FilterSupplierSerive",
    components: {
        searchSupplier,
        inputDatePicker,
        selectServiceClass,
        selectServiceType,
    },
    data () {
        return {
            date: "",
            srvCodes: [],
            classCodes: [],
            filterObj: {},
            suppliers: [],
            openSuggestions: true,
            limit: 15,
            limits: [5, 15, 30, 50, 70, 90, 100]
        }
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    methods: {
        filter (){
            this.$emit('filter', {
                begDate: this.date.start,
                endDate: this.date.end,
                saleYears: this.getYearFromDate(this.date),
                suppliers: this.suppliers,
                srvTypes: this.srvCodes,
                srvClass: this.classCodes,
                limit: this.limit
            });
        },
        getYearFromDate(date){
            let startYear = new Date(date.start).getFullYear();
            let endYear   = new Date(date.end).getFullYear();
            let years     = []
            
            do {
                years.push(endYear);
                endYear--; 
            } while (endYear >= startYear);
            
            return years
        },
        findSupplier(response) {
            if(this.suppliers.findIndex(item => item.CODE == response.CODE) < 0){
                this.suppliers.push(response.CODE)
            }
        },
        removeSupplier(item) {
            this.suppliers.splice(this.suppliers.indexOf(item), 1)
        },
        closeSuggest(){
            this.openSuggestions = !this.openSuggestions
        },

        handleServiceTypeValue(services){
            this.srvCodes = [];
            services.forEach( servivce => {
                this.srvCodes.push(servivce.CODE);
            })
        },
        handleServiceClassValue(services){
            this.classCodes = [];
            services.forEach( servivce => {
                this.classCodes.push(servivce.CODE);
            })
        },
    }
}
</script>