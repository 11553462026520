<template>
    <div class="item">
        <label for="">Categoría/Clasificación</label>
        <multiselect v-model="selected" 
            :options="options" 
            :multiple="multiple" 
            :max-height="maxHeight"
            :close-on-select="false" 
            :clear-on-select="false" 
            :preserve-search="false" 
            :label="label" 
            :track-by="trackBy" 
            @input="update">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ selectedHolder }} </span></template>
        </multiselect>
    </div>
</template>
<script>
import CatalogService from '@/services/CatalogService'
export default {
    name: "InputServiceClass",
    props: {
        multiple: { type: Boolean, default: true },
        maxHeight: { type: Number, default: 160},
        selectedHolder: { type: String, default: ""},
        label: { type: String, default: ""},
        trackBy: { type: String, default: ""},
    },
    data () {
        return {
            selected: [],
            options: []
        }
    },
    created () {
        this.getServiceClass();
    },
    methods: {
        async getServiceClass(){
            this.options = await CatalogService.getServiceClass({});            
        },
        update(){
            this.$emit('serviceClassVal', this.selected);
        },
    }

}
</script>